// formik
import { Formik, Form, Field } from "formik";

// yup
import * as Yup from "yup";

// function
import sendEmail from "../functions/sendEmail";

function ContactForm() {
  const emailSchema = Yup.object().shape({
    name: Yup.string("Nome invalido")
      .min(3)
      .required("É necessário preencher o campo nome"),
    email: Yup.string("Endereço de email é ínválido")
      .email("Endereço de email é ínválido")
      .required("é necessário preecher o campo email"),
  });

  return (
    <div>
      <Formik
        initialValues={{ email: "", name: "", message: "" }}
        validationSchema={emailSchema}
        onSubmit={ async (values) => {
          await sendEmail(values.email, values.name, values.message);
        }}
      >
        <Form>
          <div className="md:flex md:items-center mb-6">
            <div className="w-full">
              <Field
                className="bg-gray-200 appearance-none border-0 border-gray-200 rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white "
                id="name"
                type="text"
                placeholder="Seu nome"
                name="name"
              />
            </div>
          </div>
          <div className="md:flex md:items-center mb-6">
            <div className="w-full">
              <Field
                className="bg-gray-200 w-full appearance-none border-0 border-gray-200 rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white"
                id="email"
                type="email"
                placeholder="Email"
                name="email"
              />
            </div>
          </div>
          <div className="md:flex md:items-center mb-6">
            <div className="w-full">
              <Field
                className="bg-gray-200 w-full appearance-none border-0 border-gray-200 rounded w-full py-8 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white"
                id="message"
                placeholder="Mensagem"
                name="message"
              />
            </div>
          </div>
          <div className="md:flex md:items-left">
            <div className="w-full">
              <button
                className="shadow bg-purple-500 transition duration-500 ease-in-out hover:bg-green-600 transform focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                type="submit"
                value="Send"
              >
                Enviar Mensagem
              </button>
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export default ContactForm;
