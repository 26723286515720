import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// pages
import Home from "./pages/home/index";

function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
