import main_logo from "../assets/logo_original_web_grande.png";
import serasa_logotipo from "../assets/logotipo-serasa-experian-eid.png";

function Footer() {
  return (
    <footer className="text-white p-8" style={{ backgroundColor: "#6592e6" }}>
      <div className="container mx-auto px-8">
        <div className="w-full flex flex-col md:flex-row py-6">
          <div className="flex-1 mb-6">
            <img src={main_logo} />
          </div>
          <div className="flex-1">
            <p className="uppercase md:mb-6">E4 Sistemas & Tecnologia</p>
            <ul className="list-reset mb-6">
              <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                Curitiba PR | São Paulo SP
              </li>
              <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                (41) 99893-0506 | (11) 94821-9152
              </li>

              <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                contato@e4sistemas.com.br
              </li>

              <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                Segunda à Sexta das 8:00 às 18:00 hs
              </li>
            </ul>
          </div>
          <div className="flex flex-col text-left md:text-center">
            <img
              src={serasa_logotipo}
              className=" md:self-center self-start w-auto h-auto"
            />
            <h1 className="text-2xl text-bold">Certificado Digital</h1>
            <p className="text-sm my-2">
              Compre/Renove seu Certificado Digital, e ganhe até 10% de Desconto
            </p>
            <br />
            <a
              href="https://serasa.certificadodigital.com.br/clube-do-revendedor/cupom/?cp=CRFRA43122"
              className="no-underline"
            >
              <button className="bg-white transition duration-500 ease-in-out transform hover:scale-x-110 text-black py-2 my-2 px-7 mr-5 rounded">
                Compre Agora!!!
              </button>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
