// axios
import axios from "axios";

async function sendEmail(email, name, message) {
  try {
    await axios.post("https://email.habilis.net.br", {
      email,
      message,
      name,
    });
  } catch (error) {
    console.log(error);
  }
}

export default sendEmail;
