// react
import { useRef } from "react";

// react scroll
import { Link } from "react-scroll";

// images
import hero_image from "../../assets/hero.png";
import blue_background from "../../assets/fundo-azul-1-2000x1500.jpg";
import white_background from "../../assets/background11.jpg";
import phone_tablet from "../../assets/pexels-pixabay-40739.jpg";
import i_mac from "../../assets/67010fed89bc413fbb1f2e5833073b2d-23-1280-768x455.jpg";
import pdv_image from "../../assets/mockuper-2-768x531.ac4f899f.png";
import mac_computer from "../../assets/smartmockups-kkfuwryw-personalizado-768x480.png";
import abstract_image from "../../assets/background1.jpg";
import woman from "../../assets/mulher-970x1333.jpg";
import man_at_store from "../../assets/lojaderoupas-970x1333.jpg";
import yubo from "../../assets/yuoo-913x1335.jpg";
import cooking from "../../assets/171126-5-qualidades-para-ser-um-cozinheiro-de-primeira-1317x879.jpg";
import system_screen_one from "../../assets/0303-1-1347x663-800x394.jpg";
import system_screen_two from "../../assets/0302-1349x669.jpg";
import system_screen_three from "../../assets/pdv-1366x667.jpg";
import system_screen_four from "../../assets/010203-1366x668.jpg";
import system_screen_five from "../../assets/tela-milersoft-cloud-nas-nuvens-1366x768.png";
import system_screen_six from "../../assets/foto-tela-restaurante-novo-delivery-1600x900.png";
import system_screen_seven from "../../assets/ponto-de-vendas-tela-meio-de-um-cupom-1212x646-800x426.png";
import system_screen_eight from "../../assets/monitor-do-sistema-1600x900.png";
import smartphone from "../../assets/smartmockups-kkfuvl3l-911x659.png";
import shopping from "../../assets/mercado-600x600.jpg";
import hortifruti from "../../assets/hortifruti-600x600.jpg";
import bakery from "../../assets/padaria-600x600.jpg";
import building from "../../assets/matconst-600x600.jpg";
import clerk from "../../assets/44808527-young-brunette-paying-with-a-credit-card-at-the-cash-register-in-a-cake-shop-1300x866.jpg";
import phone_with_system from "../../assets/smartmockups-kkfuumup-796x998.12edcdac.png";
import main_logo from "../../assets/logo_original_web.png";

// componets
import Footer from "../../components/footer";
import ContactForm from "../../components/formContact";

// react-parllax
import { Parallax } from "react-parallax";

// font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCloudUploadAlt,
	faMobileAlt,
	faCopy,
	faShieldAlt,
	faStopwatch,
	faThumbsUp,
	faRocket,
	faClock,
	faClipboard,
	faBell,
	faDollarSign,
	faReceipt,
	faCheckSquare,
	faCalendarAlt,
	faCommentAlt,
	faDesktop,
	faMotorcycle,
	faPaperPlane,
	faMousePointer,
	faBreadSlice,
	faHammer,
	faLeaf,
	faShoppingCart,
	faTablet,
	faArrowDown,
} from "@fortawesome/free-solid-svg-icons";

function Home() {
	const scrollToRef = useRef(null);

	const gotoContactSection = () =>
		window.scrollTo({
			bottom: "100px",
		});

	return (
		<div className="leading-normal tracking-normal">
			<nav
				className="fixed w-full z-30 top-0 text-white bg-black"
				style={{ backgroundColor: "#34243b" }}
			>
				<div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">
					<div className="pl-4 flex items-center">
						<img
							className="w-full"
							src={main_logo}
							alt="main_logo"
						/>
					</div>
					<div className="block lg:hidden pr-4">
						<Link
							to="contato"
							smooth={true}
							offset={-100}
							duration={500}
						>
							<button className="bg-yellow-500 text-white py-2 my-2 px-7 mr-5 rounded">
								Contato
							</button>
						</Link>
					</div>
					<div className="flex-grow px-5 lg:flex lg:items-center lg:w-auto hidden mt-2 lg:mt-0 bg-white lg:bg-transparent text-white p-4 lg:p-0 z-20 mr-5">
						<ul className="list-reset lg:flex justify-end flex-1 items-center p-5"></ul>
						<Link
							to="contato"
							smooth={true}
							offset={-100}
							duration={500}
						>
							<button className="bg-yellow-500 transition duration-500 ease-in-out hover:bg-green-600 transform hover:scale-x-110 text-white py-2 my-2 px-7 mr-5 rounded">
								Contato
							</button>
						</Link>
					</div>
				</div>
				<hr className="border-b border-gray-100 opacity-25 my-0 py-0" />
			</nav>
			<div className="container flex flex-wrap pt-20 mx-auto m-8 items-center content-center justify-center p-8">
				<div className="w-full md:w-3/5">
					<img
						src={hero_image}
						className="w-full md:4/5 focus:shadow-outline transform transition hover:scale-105 duration-700 ease-in-out"
						alt=""
					/>
				</div>
				<div className="sm:items-center lg:p-10 lg:pl-60 lg:pr-60 m-8">
					<h1 className="font-bold leading-tight text-gray-800 text-3xl">
						E4 Sistemas. Soluções para sua
						empresa
					</h1>
					<br />
					<p className="text-gray-600 text-lg">
						Uma empresa que entrega as mais
						novas soluções para sua empresa.
						Emissão de documentos fiscais,
						controle de estoque, balanço
						financeiro e muito mais. Conheça
						nossas ferramentas e deixe com a
						E4 Sistemas o resto.
					</p>
				</div>
			</div>
			<section className="bg-white h-auto text-white grid grid-cols-1 md:grid-cols-3">
				<img
					src={phone_tablet}
					className="w-full md:col-span-2"
					alt="phone_tablet"
				/>
				<div
					className="p-8 text-center flex flex-row justify-center items-center"
					style={{ backgroundColor: "#6f5ad3" }}
				>
					<span></span>
					<h1 className="text-3xl">
						<FontAwesomeIcon
							icon={faTablet}
							className="text-6xl mr-2"
						/>
						<FontAwesomeIcon
							icon={faMobileAlt}
							className="text-5xl"
						/>
						<br />
						Faça Emissão de seus cupons
						fiscais de Tablet's e
						Smartphones.
					</h1>
				</div>
			</section>
			<section
				className="py-8 text-white"
				style={{
					background:
						"linear-gradient(90deg, rgba(61, 61, 184, 255), rgba(177, 201, 227, 0.3)), url(" +
						white_background +
						")",
				}}
			>
				<div className="container mx-auto flex flex-wrap pt-4 pb-12">
					<div className="w-full mb-4">
						<div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
					</div>
					<div className="w-full md:w-1/4 p-6 flex flex-col flex-grow flex-shrink">
						<div className="flex-1 rounded-t rounded-b-none overflow-hidden content-center">
							<h1 className="font-bold leading-tight px-6 text-5xl text-left">
								Tecnologia de
								ponta
							</h1>
							<p className="text-base px-6 mb-5 text-left">
								Nossos sistemas
								são responsivos,
								podem ser
								acessados de
								qualquer lugar e
								possuem níveis
								de acesso, o que
								melhorar em
								muito o controle
								de acesso e
								segurança.
							</p>
							<Link
								to="contato"
								smooth={true}
								offset={-100}
								duration={500}
							>
								<button
									id="test_gratis"
									className="bg-white w-auto transition duration-500 ease-in-out hover:bg-green-600 transform hover:scale-x-110 text-gray-600 hover:text-white py-2 my-2 px-7 ml-5 rounded"
								>
									Teste
									grátis
								</button>
							</Link>
						</div>
					</div>
					<div className="w-full md:w-1/5 p-8 flex flex-col flex-grow flex-shrink">
						<img
							src={phone_with_system}
							className="w-full md:w-4/5"
							alt=""
						/>
					</div>
				</div>
				<div className="container mx-auto flex flex-wrap pt-4 pb-12">
					<div className="w-full lg:w-1/4 md:w-1/2 p-6 flex flex-col flex-grow flex-shrink">
						<h1 className="font-bold leading-tight px-6 text-2xl uppercase my-5 text-5xl">
							<FontAwesomeIcon
								icon={
									faMobileAlt
								}
							/>
						</h1>
						<h1 className="leading-tight px-6 text-2xl uppercase text-yellow-200">
							<span className="font-bold">
								R
							</span>
							esponsivo
						</h1>
						<p className="text-base px-6 mb-5">
							Pode ser acessado em
							Tablet´s, Computadores,
							Noteboolk´s e
							Smartphone´s
						</p>
					</div>
					<div className="w-full md:w-1/2 lg:w-1/4 p-6 flex flex-col flex-grow flex-shrink">
						<h1 className="font-bold leading-tight px-6 text-2xl uppercase my-5 text-5xl">
							<FontAwesomeIcon
								icon={faRocket}
							/>
						</h1>
						<h1 className="leading-tight px-6 text-2xl uppercase text-yellow-200">
							<span className="font-bold">
								D
							</span>
							escomplicado
						</h1>
						<p className="text-base px-6 mb-5">
							Acompanhe nossos vídeos
							de treinamento e receba
							o treinamento.
						</p>
					</div>
					<div className="w-full md:w-1/2 lg:w-1/4 p-6 flex flex-col flex-grow flex-shrink">
						<h1 className="font-bold leading-tight px-6 text-2xl uppercase my-5 text-5xl">
							<FontAwesomeIcon
								icon={faClock}
							/>
						</h1>
						<h1 className="leading-tight px-6 text-2xl uppercase text-yellow-200">
							<span className="font-bold">
								C
							</span>
							ompleto
						</h1>
						<p className="text-base px-6 mb-5">
							Acesso a todos os
							módulos mais completos
							do mercado e
							atualizações.
						</p>
					</div>
					<div className="w-full md:w-1/2 lg:w-1/4 p-6 flex flex-col flex-grow flex-shrink">
						<h1 className="font-bold leading-tight px-6 text-2xl uppercase my-5 text-5xl">
							<FontAwesomeIcon
								icon={faClock}
							/>
						</h1>
						<h1 className="leading-tight px-6 text-2xl uppercase text-yellow-200">
							<span className="font-bold">
								A
							</span>
							cessibilidade
						</h1>
						<p className="text-base px-6 mb-5 mt-3">
							Nossos sistemas WEB
							podem ser acessados
							mesmo fora de sua empres
						</p>
					</div>
				</div>
			</section>
			<section
				className="p-10 h-auto text-white"
				style={{ backgroundColor: "#34243b" }}
			>
				<div className="container flex flex-wrap items-center justify-center px-4 py-8 mx-auto lg:justify-between">
					<div className="flex flex-wrap justify-center text-left md:w-1/2">
						<h1 className="text-xl">
							Acesse:
						</h1>
					</div>
					<div className="flex justify-center mt-4 lg:mt-0 w-full md:w-1/2">
						<a
							href="https://www.facebook.com/e4sistemas/"
							className="flex items-center justy-center rounded-full p-2 text-white border-white border-2 hover:bg-yellow-500 transition duration-400 cursor-pointer"
						>
							<svg
								fill="currentColor"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								className="w-5 h-5"
								viewBox="0 0 24 24"
							>
								<path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
							</svg>
						</a>
						<a
							href="https://www.instagram.com/e4sistemas/"
							className="flex items-center justy-center rounded-full p-2 ml-3 text-white border-white border-2 hover:bg-yellow-500 transition duration-400 cursor-pointer"
						>
							<svg
								fill="none"
								stroke="currentColor"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								className="w-5 h-5"
								viewBox="0 0 24 24"
							>
								<rect
									width="20"
									height="20"
									x="2"
									y="2"
									rx="5"
									ry="5"
								></rect>
								<path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
							</svg>
						</a>
					</div>
				</div>
			</section>
			<Parallax bgImage={blue_background} strength={300}>
				<section className="text-white border-b py-8">
					<div className="container mx-auto flex flex-wrap pt-4 pb-12 text-center sm:text-left">
						<h1 className="w-full my-5 text-3xl font-bold leading-tight text-center text-white">
							Nossos Sistemas
						</h1>
						<div className="w-full mb-4">
							<div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
						</div>
						<div className="w-full md:w-2/4 p-6 flex flex-wrap container text-center md:text-left">
							<div className="w-full md:w-1/5 text-center text-6xl">
								<FontAwesomeIcon
									icon={
										faCloudUploadAlt
									}
								/>
							</div>
							<div className="w-full md:w-4/5 my-5 md:my-0">
								<span className="flex flex-wrap no-underline hover:no-underline">
									<p className="w-full text-xs md:text-sm px-6"></p>
									<div className="w-full font-bold text-3xl px-6 uppercase">
										habilis
										start
									</div>
									<p className="text-xl px-6 mb-5">
										Inclui
										o
										modulo
										de
										pré-venda
										e
										Pdv
										Mei
										WE
									</p>
								</span>
							</div>
						</div>
						<div className="w-full md:w-2/4 p-6 flex flex-wrap container text-center md:text-left">
							<div className="w-full md:w-1/5 text-center text-6xl">
								<FontAwesomeIcon
									icon={
										faMobileAlt
									}
								/>
							</div>
							<div className="w-full md:w-4/5 my-5 md:my-0">
								<span className="flex flex-wrap no-underline hover:no-underline">
									<p className="w-full text-xs md:text-sm px-6"></p>
									<div className="w-full font-bold text-3xl px-6 uppercase">
										habilis
										o.s
									</div>
									<p className="text-xl px-6 mb-5">
										Inclui
										os
										modelos
										de
										Emissão
										de
										NFSe
										e
										Ordem
										de
										Serviço!
									</p>
								</span>
							</div>
						</div>
						<div className="w-full md:w-2/4 p-6 flex flex-wrap container text-center md:text-left">
							<div className="w-full md:w-1/5 text-center text-6xl">
								<FontAwesomeIcon
									icon={
										faCopy
									}
								/>
							</div>
							<div className="w-full md:w-4/5">
								<span className="flex flex-wrap no-underline hover:no-underline my-5 md:my-0">
									<p className="w-full text-xs md:text-sm px-6"></p>
									<div className="w-full font-bold text-3xl px-6 uppercase">
										habilis
										n.f
									</div>
									<p className="text-xl px-6 mb-5">
										Inclui
										os
										módulos
										de
										emissão
										de
										NFe,
										Emissão
										de
										NFCe
										e
										PDV
										NFCe
										WEB!
									</p>
								</span>
							</div>
						</div>
						<div className="w-full md:w-2/4 p-6 flex flex-wrap container text-center md:text-left">
							<div className="w-full md:w-1/5 text-center text-6xl">
								<FontAwesomeIcon
									icon={
										faShieldAlt
									}
								/>
							</div>
							<div className="w-full md:w-4/5">
								<span className="flex flex-wrap no-underline hover:no-underline my-5 md:my-0">
									<p className="w-full text-xs md:text-sm px-6"></p>
									<div className="w-full font-bold text-3xl px-6 uppercase">
										habilis
										store
									</div>
									<p className="text-xl px-6 mb-5">
										Inclui
										os
										módulos
										de
										emissão
										de
										NFe,
										Emissão
										de
										NFCe
										e
										PDV
										NFCe
										WEB,
										Emissão
										de
										NFSe
										e
										Ordem
										de
										serviço!
									</p>
								</span>
							</div>
						</div>
						<div className="w-full md:w-2/4 p-6 flex flex-wrap container text-center md:text-left">
							<div className="w-full md:w-1/5 text-center text-6xl">
								<FontAwesomeIcon
									icon={
										faStopwatch
									}
								/>
							</div>
							<div className="w-full md:w-4/5 my-5 md:my-0">
								<span className="flex flex-wrap no-underline hover:no-underline">
									<p className="w-full text-xs md:text-sm px-6"></p>
									<div className="w-full font-bold text-3xl px-6 uppercase">
										habilis
										n.f.
										plus
									</div>
									<p className="text-xl px-6 mb-5">
										Inclui
										os
										módulos
										de
										emissão
										de
										NFe,
										Emissão
										de
										NFCe,
										PDV
										NFCe
										Web,
										Pré-venda
										e
										Pdv
										Mei
										WEB!
									</p>
								</span>
							</div>
						</div>
						<div className="w-full md:w-2/4 p-6 flex flex-wrap container text-center md:text-left">
							<div className="w-full md:w-1/5 text-center text-6xl">
								<FontAwesomeIcon
									icon={
										faThumbsUp
									}
								/>
							</div>
							<div className="w-full md:w-4/5">
								<span className="flex flex-wrap no-underline hover:no-underline my-5 md:my-0">
									<p className="w-full text-xs md:text-sm px-6"></p>
									<div className="w-full font-bold text-3xl px-6 uppercase">
										habilis
										office
									</div>
									<p className="text-xl px-6 mb-5">
										Inclui
										os
										módulos
										de
										emissão
										de
										NFe,
										Emissão
										de
										NFCe
										e
										PDV
										NFCe
										WEB,
										Emissão
										de
										NFSe,
										Ordem
										de
										serviço,
										pré-venda
										e
										Pdv
										Mei
										WEB!
									</p>
								</span>
							</div>
						</div>
					</div>
				</section>
			</Parallax>
			<section className="bg-white border-b py-8">
				<div className="container mx-auto flex flex-wrap pt-4 pb-12 text-center sm:text-left">
					<h1
						className="w-full my-2 mb-8 align-top inline-block font-bold text-5xl"
						style={{
							color: "#59bb93",
							lineHeight: "1.1",
						}}
					>
						<span
							style={{
								color: "#ffd96a",
								fontSize: "5rem",
								paddingRight:
									"0rem",
								marginTop: "-1rem",
							}}
						>
							<FontAwesomeIcon
								icon={
									faMousePointer
								}
								className="mr-0"
							/>
						</span>
						Empresa
					</h1>
					<div className="w-full mb-4 mt-8">
						<div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
					</div>
					<div className="w-full lg:w-1/3 md:w-1/2 text-center md:text-left flex flex-col flex-grow flex-shrink p-5">
						<div className="rounded overflow-hidden">
							<img
								className="w-full hover:scale-110 transform duration-300 ease-in-out"
								src={i_mac}
								alt="Sunset in the mountains"
							/>
							<div className="px-6 py-4">
								<div className="text-2xl mb-2 uppercase">
									<span className="font-bold">
										erp
										c
									</span>
									ompleto
								</div>
								<p className="text-gray-700 text-base">
									Lorem
									ipsum
									dolor
									sit
									amet,
									consectetur
									adipisicing
									elit.
									Voluptatibus
									quia,
									nulla!
									Maiores
									et
									perferendis
									eaque,
									exercitationem
									praesentium
									nihil.
								</p>
							</div>
						</div>
					</div>
					<div className="w-full lg:w-1/3 md:w-1/2 flex flex-col text-center md:text-left flex-grow flex-shrink p-5">
						<div className="rounded overflow-hidden">
							<img
								className="w-full hover:scale-110 transform duration-300 ease-in-out"
								src={pdv_image}
								alt="Sunset in the mountains"
							/>
							<div className="px-6 py-4">
								<div className="text-2xl mb-2 uppercase">
									<span className="font-bold">
										e
									</span>
									missão
									de{" "}
									<span className="font-bold">
										nfc-
									</span>
									e
								</div>
								<p className="text-gray-700 text-base">
									Lorem
									ipsum
									dolor
									sit
									amet,
									consectetur
									adipisicing
									elit.
									Voluptatibus
									quia,
									nulla!
									Maiores
									et
									perferendis
									eaque,
									exercitationem
									praesentium
									nihil.
								</p>
							</div>
						</div>
					</div>
					<div className="w-full lg:w-1/3 md:w-full flex flex-col text-center md:text-left flex-grow flex-shrink p-5">
						<div className="rounded overflow-hidden">
							<img
								className="w-full hover:scale-110 transform duration-300 ease-in-out"
								src={
									mac_computer
								}
								alt="Sunset in the mountains"
							/>
							<div className="px-6 py-4">
								<div className="text-2xl mb-2 uppercase">
									varejo
									em geral
								</div>
								<p className="text-gray-700 text-base">
									Lorem
									ipsum
									dolor
									sit
									amet,
									consectetur
									adipisicing
									elit.
									Voluptatibus
									quia,
									nulla!
									Maiores
									et
									perferendis
									eaque,
									exercitationem
									praesentium
									nihil.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<div
				className="w-full h-3"
				style={{ backgroundColor: "#34243b" }}
			/>
			<section>
				<div className=" mx-auto flex flex-wrap text-left text-white">
					<div
						className="w-full lg:w-1/3 md:w-1/2 flex h-auto p-5 min-h-screen"
						style={{
							backgroundImage:
								"url(" +
								woman +
								")",
							backgroundRepeat:
								"no-repeat",
							backgroundSize:
								"100% 100%",
						}}
					>
						<div className="px-6 py-4 self-end">
							<div className="text-xl mb-2 uppercase">
								<span className="font-bold">
									q
								</span>
								ualidade
							</div>
							<p className="text-base">
								Nossas soluções
								atendem suas
								necessidades em
								todos os
								segmentos.
							</p>
						</div>
					</div>
					<div
						className="w-full lg:w-1/3 md:w-1/2 flex h-screen p-5 min-h-screen"
						style={{
							backgroundImage:
								"url(" +
								man_at_store +
								")",
							backgroundRepeat:
								"no-repeat",
							backgroundSize:
								"100% 100%",
						}}
					>
						<div className="px-6 py-4 self-end">
							<div className="text-xl mb-2 uppercase">
								<span className="font-bold">
									t
								</span>
								ecnologia
							</div>
							<p className="text-base">
								Sistemas
								atualizados
								periodicamente e
								automaticamente
							</p>
						</div>
					</div>
					<div
						className="w-full lg:w-1/3 md:w-1/2 flex h-screen p-5 min-h-screen"
						style={{
							backgroundImage:
								"url(" +
								yubo +
								")",
							backgroundRepeat:
								"no-repeat",
							backgroundSize:
								"100% 100%",
						}}
					>
						<div className="px-6 py-4 self-end">
							<div className="text-xl mb-2 uppercase">
								<span className="font-bold">
									s
								</span>
								uporte
							</div>
							<p className="text-base">
								Tem dúvidas?
								aqui sempre
								temos a solução
								para todos os
								segmentos
							</p>
						</div>
					</div>
				</div>
			</section>
			<div
				className="w-full h-3"
				style={{ backgroundColor: "#34243b" }}
			/>
			<section
				className="py-8 text-white text-2xl p-5 uppercase transform h-48 transition duration-300 ease-in-out"
				style={{
					background:
						"linear-gradient(90deg, #8272c1, #9796e1), url(" +
						abstract_image +
						")",
				}}
			>
				<div className="container mx-auto flex flex-wrap pt-4 pb-12 text-left m-5">
					<h1>
						acompanhe o crescimento da sua
						empresa
					</h1>
				</div>
			</section>
			<section className="bg-white text-black text-2xl">
				<div className="grid md:grid-cols-2 grid-cols-1">
					<div className="rounded overflow-hidden order-last md:order-first">
						<img
							className="w-full"
							src={cooking}
							alt="Sunset in the mountains"
						/>
					</div>
					<div className="rounded overflow-hidden container">
						<div className="px-6 py-5 my-5 text-center md:text-left flex flex-col">
							<div
								className="font-bold  mb-2 md:flex"
								style={{
									color: "#6f5ad3",
									fontSize: "3.04rem",
									lineHeight: "1.1",
									wordBreak: "break-word",
									fontWeight: "700",
								}}
							>
								<span
									style={{
										color: "#59bb93",
										fontSize: "5rem",
										display: "block",
										paddingRight:
											"0rem",
										marginTop: "-1rem",
									}}
								>
									<FontAwesomeIcon
										icon={
											faClipboard
										}
										className="mr-0"
									/>
								</span>
								E4 Sistemas
							</div>
							<p className="text-xl mt-5">
								Saiba mais sobre
								a E4 Sistemas, e
								conheça nossos
								Produtos.
							</p>
							<a
								href="https://e4sistemas.com.br"
								className="no-underline mt-8"
								style={{
									color: "#59bb93",
								}}
							>
								<button
									className="transition duration-500 ease-in-out transform hover:scale-y-110 text-white p-2 my-2 rounded py-1 items-center justy-center rounded-full text-white border-white border-2 transition duration-400 cursor-pointer"
									style={{
										backgroundColor:
											"#59bb93",
									}}
								>
									<FontAwesomeIcon
										icon={
											faArrowDown
										}
									/>
								</button>
								Saiba+
							</a>
						</div>
					</div>
				</div>
			</section>
			<section
				className="py-8 text-white text-lg h-auto"
				style={{
					backgroundImage:
						"url(" + blue_background + ")",
				}}
			>
				<div className="container mx-auto pt-4 text-left m-5 mx-5">
					<div
						className="w-full my-2 mb-8 align-top flex uppercase"
						style={{
							wordBreak: "break-word",
						}}
					>
						<span
							style={{
								boxSizing: "border-box",
								fontSize: "5rem",
								color: "#59bb93",
								paddingRight:
									"0rem",
								marginRight:
									"-1rem",
								display: "block",
							}}
						>
							<FontAwesomeIcon
								icon={faBell}
								className="text-5xl "
							/>
						</span>
						<span
							style={{
								fontSize: "1.6rem",
								lineHeight: "1.5",
							}}
						>
							funcionalidades
						</span>
					</div>
					<div className="grid grid-cols-1 md:grid-cols-4">
						<div className="text-center p-5">
							<span
								className="flex items-center justify-center m-auto text-white"
								style={{
									display: "block",
									fontSize: "3rem",
									padding: "2.6rem",
									width: "fit-content",
									marginBottom:
										"1rem",
									backgroundColor:
										"#6a5fcc",
									borderRadius:
										"8px",
								}}
							>
								<FontAwesomeIcon
									icon={
										faDollarSign
									}
								/>
							</span>
							<span
								style={{
									fontSize: "1rem",
									lineHeight: "1.5",
									textAlign: "center",
									fontWeight: 500,
									wordBreak: "break-word",
									boxSizing: "border-box",
								}}
							>
								Controle
								financeiro
							</span>
						</div>
						<div className="text-center p-5">
							<span
								className="flex items-center justify-center m-auto text-white"
								style={{
									display: "block",
									fontSize: "3rem",
									padding: "2.6rem",
									width: "fit-content",
									marginBottom:
										"1rem",
									backgroundColor:
										"#6a5fcc",
									borderRadius:
										"8px",
								}}
							>
								<FontAwesomeIcon
									icon={
										faReceipt
									}
								/>
							</span>
							<span
								style={{
									fontSize: "1rem",
									lineHeight: "1.5",
									textAlign: "center",
									fontWeight: 500,
									wordBreak: "break-word",
									boxSizing: "border-box",
								}}
							>
								Emissão
							</span>
						</div>
						<div className="text-center p-5">
							<span
								className="flex items-center justify-center m-auto text-white"
								style={{
									display: "block",
									fontSize: "3rem",
									padding: "2.6rem",
									width: "fit-content",
									marginBottom:
										"1rem",
									backgroundColor:
										"#6a5fcc",
									borderRadius:
										"8px",
								}}
							>
								<FontAwesomeIcon
									icon={
										faCheckSquare
									}
								/>
							</span>
							<span
								style={{
									fontSize: "1rem",
									lineHeight: "1.5",
									textAlign: "center",
									fontWeight: 500,
									wordBreak: "break-word",
									boxSizing: "border-box",
								}}
							>
								Controle de
								estoque
							</span>
						</div>
						<div className="text-center p-5">
							<span
								className="flex items-center justify-center m-auto text-white"
								style={{
									display: "block",
									fontSize: "3rem",
									padding: "2.6rem",
									width: "fit-content",
									marginBottom:
										"1rem",
									backgroundColor:
										"#6a5fcc",
									borderRadius:
										"8px",
								}}
							>
								<FontAwesomeIcon
									icon={
										faCalendarAlt
									}
								/>
							</span>
							<span
								style={{
									fontSize: "1rem",
									lineHeight: "1.5",
									textAlign: "center",
									fontWeight: 500,
									wordBreak: "break-word",
									boxSizing: "border-box",
								}}
							>
								Central de
								cobrança
							</span>
						</div>
						<div className="text-center p-5">
							<span
								className="flex items-center justify-center m-auto text-white"
								style={{
									display: "block",
									fontSize: "3rem",
									padding: "2.6rem",
									width: "fit-content",
									marginBottom:
										"1rem",
									backgroundColor:
										"#6a5fcc",
									borderRadius:
										"8px",
								}}
							>
								<FontAwesomeIcon
									icon={
										faStopwatch
									}
								/>
							</span>
							<span
								style={{
									fontSize: "1rem",
									lineHeight: "1.5",
									textAlign: "center",
									fontWeight: 500,
									wordBreak: "break-word",
									boxSizing: "border-box",
								}}
							>
								Ordem de serviço
							</span>
						</div>
						<div className="text-center p-5">
							<span
								className="flex items-center justify-center m-auto text-white"
								style={{
									display: "block",
									fontSize: "3rem",
									padding: "2.6rem",
									width: "fit-content",
									marginBottom:
										"1rem",
									backgroundColor:
										"#6a5fcc",
									borderRadius:
										"8px",
								}}
							>
								<FontAwesomeIcon
									icon={
										faCommentAlt
									}
								/>
							</span>
							<span
								style={{
									fontSize: "1rem",
									lineHeight: "1.5",
									textAlign: "center",
									fontWeight: 500,
									wordBreak: "break-word",
									boxSizing: "border-box",
								}}
							>
								Pré-venda
							</span>
						</div>
						<div className="text-center p-5">
							<span
								className="flex items-center justify-center m-auto text-white"
								style={{
									display: "block",
									fontSize: "3rem",
									padding: "2.6rem",
									width: "fit-content",
									marginBottom:
										"1rem",
									backgroundColor:
										"#6a5fcc",
									borderRadius:
										"8px",
								}}
							>
								<FontAwesomeIcon
									icon={
										faDesktop
									}
								/>
							</span>
							<span
								style={{
									fontSize: "1rem",
									lineHeight: "1.5",
									textAlign: "center",
									fontWeight: 500,
									wordBreak: "break-word",
									boxSizing: "border-box",
								}}
							>
								PDV MEI
							</span>
						</div>
						<div className="text-center p-5">
							<span
								className="flex items-center justify-center m-auto text-white"
								style={{
									display: "block",
									fontSize: "3rem",
									padding: "2.6rem",
									width: "fit-content",
									marginBottom:
										"1rem",
									backgroundColor:
										"#6a5fcc",
									borderRadius:
										"8px",
								}}
							>
								<FontAwesomeIcon
									icon={
										faMotorcycle
									}
								/>
							</span>
							<span
								style={{
									fontSize: "1rem",
									lineHeight: "1.5",
									textAlign: "center",
									fontWeight: 500,
									wordBreak: "break-word",
									boxSizing: "border-box",
								}}
							>
								Delivery
							</span>
						</div>
					</div>
				</div>
			</section>
			<section className="border-b py-8 text-black text-lg p-0 bg-white">
				<div className="flex flex-wrap pt-4 pb-12 text-center">
					<h1 className="w-full my-2 mb-8 text-2xl align-top inline-block font-bold text-gray-800">
						Telas dos nossos sistemas
					</h1>

					<div className="mx-auto space-y-2 lg:space-y-3 lg:gap-2 grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4">
						<div className="w-full">
							<img
								className="w-full"
								src={
									system_screen_one
								}
								alt="Sunset in the mountains"
							/>
						</div>

						<div className="w-full">
							<img
								className="w-full"
								src={
									system_screen_two
								}
								alt="Sunset in the mountains"
							/>
						</div>
						<div className="w-full">
							<img
								className="w-full"
								src={
									system_screen_three
								}
								alt="Sunset in the mountains"
							/>
						</div>
						<div className="w-full">
							<img
								className="w-full"
								src={
									system_screen_four
								}
								alt="Sunset in the mountains"
							/>
						</div>

						<div className="w-full">
							<img
								className="w-full"
								src={
									system_screen_five
								}
								alt="Sunset in the mountains"
							/>
						</div>
						<div className="w-full">
							<img
								className="w-full"
								src={
									system_screen_six
								}
								alt="Sunset in the mountains"
							/>
						</div>
						<div className="w-full">
							<img
								className="w-full"
								src={
									system_screen_seven
								}
								alt="Sunset in the mountains"
							/>
						</div>
						<div className="w-full p-5">
							<img
								className="w-full"
								src={
									system_screen_eight
								}
								alt="Sunset in the mountains"
							/>
						</div>
					</div>
				</div>
			</section>
			<section
				className="w-full p-5 grid grid-cols-1 lg:grid-cols-2 text-white"
				style={{
					backgroundImage:
						"url(" + blue_background + ")",
				}}
			>
				<div
					className="w-full my-2 mb-8  md:p-5 align-top text-center md:text-left"
					style={{ wordBreak: "break-word" }}
				>
					<span
						style={{
							fontSize: "5rem",
							color: "#ffd96a",
							paddingRight: "0rem",
							marginRight: "-1rem",
						}}
					>
						<FontAwesomeIcon
							icon={faPaperPlane}
							className="text-5xl "
						/>
					</span>
					<span
						style={{
							fontSize: "1.6rem",
							lineHeight: "1.5",
							color: "#6f5ad3",
						}}
					>
						NOSSO DIFERENCIAL
					</span>
					<p>
						Atendemos em todo território
						nacional.
						<br />
						<br />
						<span
							style={{
								color: "#ff9966",
							}}
						>
							Suporte
						</span>{" "}
						Atendimento rápido e completo{" "}
						<br />
						<br />
						<span
							style={{
								color: "#ff9966",
							}}
						>
							Técnica.
						</span>{" "}
						Vídeos de treinamento para
						melhor atender a sua demanda
						<br /> Adquira já: Melhor custo
						x benefício <br />
						<br />{" "}
						<span
							style={{
								color: "#ff9966",
							}}
						>
							Multi empresa/ Multi
							lojas.
						</span>{" "}
						Acesse suas lojas em uma única
						tela
					</p>
				</div>
				<img src={smartphone} alt="smartphone" />
			</section>
			<section className="mx-5 p-5 mb-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 border-b py-8 text-black text-lg bg-white">
				<h1
					className="w-full my-2 mb-8 align-top inline-block font-bold text-left col-span-1 md:col-span-2 lg:col-span-4 text-center md:text-left"
					style={{
						color: "#59bb93",
						fontSize: "1.6rem",
						lineHeight: "1.5",
					}}
				>
					Atuação
				</h1>
				<div>
					<img
						src={shopping}
						alt="mercado"
						className="w-full"
					/>
				</div>
				<div
					className="bg-black text-white p-5"
					style={{ backgroundColor: "#6f5ad3" }}
				>
					<FontAwesomeIcon
						icon={faShoppingCart}
						className="text-3xl"
					/>
					<h1 className="text-xl font-bold mt-2">
						Mercado
					</h1>
					<span className="text-base">
						Mercadinho, quitanda
					</span>
				</div>
				<div>
					<img
						src={hortifruti}
						alt="frutaria"
						className="w-full"
					/>
				</div>
				<div
					className="text-white p-5"
					style={{ backgroundColor: "#27b7eb" }}
				>
					<FontAwesomeIcon
						icon={faLeaf}
						className="text-3xl"
					/>
					<h1 className="text-xl font-bold mt-2">
						Hortifruti
					</h1>
					<span className="text-base">
						Sacolões, frutaria
					</span>
				</div>
				<div>
					<img
						src={bakery}
						alt="padaria"
						className="w-full"
					/>
				</div>
				<div
					className="text-white p-5"
					style={{ backgroundColor: "#faaf3f" }}
				>
					<FontAwesomeIcon
						icon={faBreadSlice}
						className="text-3xl"
					/>
					<h1 className="text-xl font-bold mt-2">
						Padarias
					</h1>
					<span className="text-base">
						Docerias, lojas de bolo
					</span>
				</div>
				<div>
					<img
						src={building}
						alt="construcao"
						className="w-full"
					/>
				</div>
				<div
					className="text-white p-5"
					style={{ backgroundColor: "#59bb93" }}
				>
					<FontAwesomeIcon
						icon={faHammer}
						className="text-3xl"
					/>
					<h1 className="text-xl font-bold mt-2">
						Construção
					</h1>
					<span className="text-base">
						Lojas de material de construção
					</span>
				</div>
			</section>
			<Parallax
				bgImage={clerk}
				strength={200}
				className="mt-8"
				ref={scrollToRef}
			>
				<div
					className="h-auto p-8"
					style={{
						background: "linear-gradient(90deg, rgba(61, 61, 184, 255),  rgba(104, 120, 230, 0.41))",
					}}
					id="contato"
					name="contato"
				>
					<div className="container mx-0 md:mx-8 p-0 md:p-8 grid gric-cols-1 place-items-center m-8">
						<div className="w-full md:w-7/12">
							<h1
								className="text-left text-white mb-5"
								style={{
									fontSize: "1.6rem",
									lineHeight: "1.5",
									fontWeight: "400",
								}}
							>
								Contato
							</h1>
							<ContactForm />
						</div>
					</div>
				</div>
			</Parallax>
			<section>
				<iframe
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3603.4409131317398!2d-49.27429507757277!3d-25.42351875938022!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94dce58e64e1c059%3A0x66bfb8778f7f549d!2sE4%20Sistemas!5e0!3m2!1sen!2sbr!4v1634003430800!5m2!1sen!2sbr"
					title="a title"
					width="100%"
					height="450"
					style={{ border: 0 }}
					allowFullScreen=""
					loading="lazy"
				/>
			</section>
			<Footer />
		</div>
	);
}

export default Home;
